import { Button, Card, CardHeader , Text, makeStyles, 
    shorthands, Subtitle1, CardFooter, 
    Combobox, Field, Option, Body1, 
    Caption1, Divider, Input } from '@fluentui/react-components';
import {useEffect, useState, useContext} from 'react';
import { GraphService } from '../services/GraphService';
import { ErrorCircle16Filled, PresenceAvailable16Filled } from '@fluentui/react-icons';
import { appStyles } from '../Styles';
import { StyklrService } from '../services/StyklrService';
import { useAccount } from '@azure/msal-react';
import { InfoContext } from '../AppContext';

export type NewSiteRequestItem = {
    title:string;
    onSave: () => void;
    onCancel: () => void;
}

const styleClasses = makeStyles({
    root: {
        display:"grid",
        width: "100%",
        "grid-template-columns": "repeat(auto-fill, minmax(400px, 1fr))",
        ...shorthands.margin("10px")
    },
    box: {
        ...shorthands.margin("10px"),
        ...shorthands.padding("10px"),
        // ...shorthands.borderRight("1px", "solid", tokens.colorNeutralBackground6)
    },
    optionItem: {
        display:"flex",
        flexDirection: "column"
    },
    controlPadding: {
        ...shorthands.margin("10px", "0px", "0px", "0px")
    }   
})

export const NewSiteRequest:React.FC<NewSiteRequestItem> = ({ title, onSave, onCancel }) => {
    const { setInfo } = useContext(InfoContext);

    const account = useAccount();

    const styles = styleClasses();
    const rootStyles = appStyles();

    const [saving, setSaving] = useState<boolean>(false);


    // For the selected site from dropdown
    const [selectedSite, setSelectedSite] = useState<string[]>([""]);
    const [siteValue, setSiteValue] = useState("");
    const [customUrl, setCustomUrl] = useState("");
    // End

    // For the template dropdown
    const [selectedTemplate, setSelectedTemplate] = useState<string[]>([""]);
    const [template, setTemplate] = useState("");
    // End

    // Contains all the communication sites for showing in the dropdown
    const [allSites, setAllSites] = useState<any[]>([]);

    // For checking the validation state of the site
    const [siteValid, setSiteValid] = useState<"none" | "error" | "warning" | "success" | undefined>();

    useEffect(() => {
        GraphService.getCommunicationSites()
        .then(sites => {
            setAllSites(sites);
            console.debug(sites);
        })
        .catch(error => {
            // TODO
        });
    }, []);

    const submitForm = async () => {
        let save:boolean = false;
        setSiteValid("none");
        setSaving(true);
        let siteId:string = "";
        let siteUrl:string = "";
        if(selectedSite.length > 0 && selectedSite[0] !== "customUrl") {
            siteId = selectedSite[0];
            const site = allSites.filter(s => s.id === siteId);
            siteUrl = site[0].webUrl;
        }
        if(customUrl && customUrl.length > 0) {
            siteId = await GraphService.getSiteId(customUrl);
            siteUrl = customUrl;
        }

        try{ 
            // Site validation   
            if(selectedSite && selectedSite.length > 0) {
                //This call grants full control application permission on this site.
                await GraphService.validateSite(siteId);
                save = true;
                setSiteValid('success');
            }
        } catch(ex) {
            setSiteValid('error');
            save = false;
            setSaving(false)
        } finally {
            // Check the validation state and proceed for submission
            if(save) {
                try{
                    const res = await StyklrService.addSite(siteId, selectedTemplate[0], siteUrl, account?.username as string);
                    if(res.message === "duplicate") {
                        setSaving(false);
                        setSiteValid('warning');
                    } else if (res.message === "invalid-subscription") {
                        setSaving(false);
                        setInfo({
                            title: "Error",
                            message: "There is no valid subscription found. Please activate your subscription before submitting a site installation request"
                        });
                    } else if (res.message === "limitreached") {
                        setSaving(false);
                        setInfo({
                            title: "Error",
                            message: "The number of site installation requests provided have reached. If you need to configure additional sites, please contact support"
                        });
                    }
                    else {
                        onSave();
                    }
                } catch(ex) {
                    setSaving(false);
                    setInfo({
                        title: "Error",
                        message: "Site installation request failed. Please try again."
                    });
                }
            }
        }
    }

    const onSiteSelect = (ev:any, data:any) => {
        setSiteValid("none")
        setCustomUrl("");
        setSelectedSite(data.selectedOptions);
        setSiteValue(data.optionText ?? "");
    };

    const onTemplateSelect = (ev:any, data:any) => {
        setSelectedTemplate(data.selectedOptions);
        setTemplate(data.optionText ?? "");
    };

    return <div>
        <Card appearance='filled-alternative'>
            <CardHeader header={ <Subtitle1>{title}</Subtitle1> }>
            </CardHeader>
                <div className={styles.root}>
                    <div className={styles.box}>
                        <Field label={"Select a communications site"} required>
                            <Combobox
                                value={siteValue}
                                onOptionSelect={onSiteSelect}
                                >
                                {
                                    allSites.map(site => (
                                        <Option key={site.id} text={site.displayName} value={site.id}>
                                            <div className={styles.optionItem}>
                                                <Body1>{site.displayName}</Body1>
                                                <Caption1>{site.webUrl}</Caption1>
                                            </div>
                                        </Option>
                                    ))
                                }
                                <Option key="customUrl" text="Enter a site url" value="customUrl">
                                    <div className={styles.optionItem}>
                                        <Body1>Custom Site Url</Body1>
                                    </div>
                                </Option>
                            </Combobox>
                            { selectedSite.includes("customUrl") && (
                                <Input className={styles.controlPadding} value={customUrl} onChange={(ev, data) => {
                                    setCustomUrl(data.value);
                                }} />
                            )}
                        </Field>
                        
                        <Field
                            className={rootStyles.pageSection} 
                            label={""}
                            validationMessage={ 
                            siteValid === 'warning' ? "This is site is already submitted for provisioning": 
                            siteValid === 'error' ? "Failed to authorize": 
                            siteValid==="success"? "Authorized successfully" : 
                            " " }
                            validationState={ siteValid }
                            validationMessageIcon={ 

                                siteValid === 'error' || siteValid === 'warning' ? <ErrorCircle16Filled /> :
                                siteValid === "success" ? <PresenceAvailable16Filled/> : 
                            <></> }
                            >
                            <Text> You will have to authorize Styklr Intranet to access this site which is needed for setting up the assets. Make sure you are the site collection administrator for the selected site </Text>
                        </Field>
                    </div>

                    <div className={styles.box}>
                        <Field
                            required
                            label={"Choose a template"}
                        >
                            <Combobox
                                value={template}
                                onOptionSelect={onTemplateSelect}
                                >
                                    <Option key={'blank'} value='blank'>Blank</Option>
                                    <Option key={'template-1'} value='template-1'>Template 1</Option>
                                    <Option key={'template-2'} value='template-2'>Template 2</Option>
                                    <Option key={'template-3'} value='template-3'>Template 3</Option>
                            </Combobox>
                        </Field>
                    </div>
                </div>
                <Divider />
            <CardFooter>
                { saving ? 
                    <Button disabled appearance='secondary'>
                        Submitting
                    </Button>
                    : 
                    <Button
                        onClick={submitForm}
                        appearance='primary' 
                        disabled={!(siteValue && siteValue.length > 0) || !(template && template.length > 0)}>
                            Authorize & Submit
                    </Button>
                }
                <Button appearance='secondary' onClick={onCancel}> Cancel </Button>
            </CardFooter>
        </Card>
    </div>
}