import { CompoundButton, makeStyles,  } from "@fluentui/react-components"

import { pagesList } from "../pages/Pages";
import { useNavigate } from "react-router-dom";
import { useContext } from "react";
import { UserContext } from "../AppContext";

const styleClasses = makeStyles({
    sibeBarItems: {
        display: "flex",
        flexDirection: "column",
    },
    buttons: {
        justifyContent: "flex-start"
    }
})

export const SideBar:React.FC = () => {

    const navigate = useNavigate();
    const styles = styleClasses();

    const userInfo = useContext(UserContext);

    return <div className={styles.sibeBarItems}>
        { pagesList.filter(a => a.hidden !== true && a.isAdmin !== true && a.isSuperAdmin !== true).sort((a, b) => (a.id < b.id?-1 : 0)).map(pg => (
            <CompoundButton icon={<pg.icon />} className={styles.buttons} key={pg.id} appearance="subtle" onClick={() => { navigate(pg.path) }}> 
                {pg.title}
            </CompoundButton>    
        )) }
        {userInfo.isAdmin && (pagesList.filter(a => a.isAdmin === true).sort((a, b) => (a.id < b.id?-1 : 0)).map(pg => (
            <CompoundButton icon={<pg.icon />} className={styles.buttons} key={pg.id} appearance="subtle" onClick={() => { navigate(pg.path) }}> 
                {pg.title}
            </CompoundButton>    
        )))}
        {userInfo.isSuperAdmin && (pagesList.filter(a => a.isSuperAdmin === true).sort((a, b) => (a.id < b.id?-1 : 0)).map(pg => (
            <CompoundButton icon={<pg.icon />} className={styles.buttons} key={pg.id} appearance="subtle" onClick={() => { navigate(pg.path) }}> 
                {pg.title}
            </CompoundButton>    
        )))}
    </div>
}