import { AccountInfo } from "@azure/msal-browser";
import { useMsal } from "@azure/msal-react";
import { Dialog, DialogSurface, DialogTitle, DialogContent, DialogActions, Button } from "@fluentui/react-components"
import { useState } from "react";

export const BlockerMessage:React.FC<{errorCode:number}> = ({errorCode}) => {
    const { instance } = useMsal();
    const [account ] = useState<AccountInfo>();

    
    return <Dialog open={true}>
        <DialogSurface>
            <DialogTitle>{ errorCode === 403 ? "Access Forbidden" : "Application Unavailable" } </DialogTitle>
            <DialogContent>
                { errorCode === 403 ? 
                "You do not have access! Only a Global Administrator of the tenant or designated administrator/s can access this portal. Please reach out to your Global Administrator for getting access." 
                : 
                "An unexpected error has occured while loading the application. Please try loading again" }
            </DialogContent>
            { errorCode === 403 && (
                <DialogActions>
                <Button appearance="subtle" onClick={() => {
                    instance.logoutPopup({
                        account: account,
                        logoutHint: account?.username
                    });
                }}>Sign out</Button>
                </DialogActions>
            )}
            
        </DialogSurface>
    </Dialog>
}