import { makeStyles, tokens } from "@fluentui/react-components"
import { appStyles } from "../Styles"
import { AppsListDetail24Filled, AppGeneric32Filled } from "@fluentui/react-icons";
import { Link } from "react-router-dom";

const localStyles = makeStyles({
    tiles: {
        display: "grid",
        gridTemplateColumns: "repeat(auto-fill, minmax(150px, 1fr))",
        rowGap: "1em",
        columnGap: "1em",
    },
    tile: {
        aspectRatio: 1,
        backgroundColor: tokens.colorBrandBackground,
        color: "#FFFF",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        textDecorationLine: "none",
        '> svg': {
            flexGrow:4,
            width: "50%"
        },
        '> span': {
            flexGrow: 1,
            fontSize: "1.25rem"
        }
    }
});

export const Home:React.FC = () => {
    const styles = appStyles();
    const pageStyles = localStyles();

    return <div> 
        <div className={styles.pageArea}>
            <div className={pageStyles.tiles}>
                <Link to="/subscriptions" className={pageStyles.tile}>
                    <AppsListDetail24Filled width={"100%"} />
                    <span>Subscriptions</span>
                </Link>
                <Link to="/sites"  className={pageStyles.tile}>
                    <AppGeneric32Filled width={"100%"} />
                    <span>Sites</span>
                </Link>
            </div>
        </div>
    </div>
}