import { Spinner, Title2, Subtitle1, makeStyles, shorthands, Caption1Strong, Button, tokens, Card, CardHeader, CardFooter, CardPreview, Body1, Subtitle2 } from "@fluentui/react-components";
import { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom"
import { SaasSubscriptionStatus, StyklrService, TenantSetupStatus } from "../services/StyklrService";
import { CheckmarkCircleFilled, ErrorCircleFilled } from "@fluentui/react-icons";

import { appStyles } from "../Styles";
import moment from "moment";
import ContactSupport from "../components/ContactSupport";

const styleClasses = makeStyles({
    subscriptionInfo: {
        ...shorthands.borderRadius(tokens.borderRadiusLarge),
        backgroundColor: tokens.colorNeutralBackground4,
        ...shorthands.padding("20px"),
        display:"grid",
        gridTemplateColumns:"repeat(auto-fit, minmax(500px, 1fr))",
        '> div': {
            display: "flex",
            ...shorthands.padding("10px", "0", "0", "0"),
            '> span:first-child': {
                width:"150px",
                textAlign:"left",
                fontWeight: "bold",
            },
            '> span:not(:first-child)': {
                flexGrow:"1",
                textAlign:"left"
            }
        }
    },

    statusTiles: {
        display:"grid",
        gridTemplateColumns:"repeat(auto-fit, minmax(300px, 1fr))",
        paddingTop:"20px",
        ...shorthands.gap("20px", "20px")
    },

    cardBody: {
        ...shorthands.padding("0px", "1rem"),
        boxSizing: "border-box"
    }
});

export const SubscriptionInfo:React.FC = () => {
    const styles = appStyles();
    const localStyles = styleClasses();

    const navigate = useNavigate();

    const { id } = useParams();
    const [ subscription, setSubscription ] = useState<any>(undefined);
    const [ settings, setSettings ] = useState<any>(undefined);
    const [ error, setError ] = useState<string>(""); 

    useEffect(() => {
        if(id && id.length > 0){
            StyklrService.getSubscriptionById(id).then(res => {
                setSubscription(res.subscription);
                setSettings(res.settings);
                setError("");
            }).catch(err => {
                setSubscription(undefined);
                setError(`A subscription with the ID - ${id} is not found.`);
                console.error(err);
            });
        }
    }, [id]);

    useEffect(() => {
        if(settings?.tenantStatus === TenantSetupStatus.Configuring) {
            setTimeout(() => {
                StyklrService.getSubscriptionById(id as string).then(res => {
                    setSubscription(res.subscription);
                    setSettings(res.settings);
                    setError("");
                }).catch(err => {
                    setSubscription(undefined);
                    setError(`A subscription with the ID - ${id} is not found.`);
                    console.error(err);
                }); 
            }, 5000);
        }
    }, [settings])

    // const onSiteConfigSave = () => {
    //     setSubscription({
    //         ...subscription,
    //     });
    //     setSettings({
    //         ...settings,
    //         tenantStatus: TenantSetupStatus.Configuring
    //     });
    // }

    const termUnits:string[] = [
        "Month",
        "1 Year",
        "2 Years",
        "3 Years"
    ]

    return <div>
        <Title2 block className={styles.pageTitle}>Subscriptions
            { subscription && (<span> | {subscription['Name']}</span>) }
        </Title2>
        <div className={styles.pageArea}>
            {
            (error && error.length > 0) ? (<>
                {error}
                </>
            ):
            (!settings && !subscription) ? (
                <Spinner labelPosition="below" label={"Getting the subscription details"} />
            ):
            <>
                <div className={localStyles.subscriptionInfo}>
                    <div> 
                        <span>Name</span> <span> {subscription.Name} </span>
                    </div>
                    
                    <div> 
                        <span>Subscription ID</span> <span> {subscription.RowKey} </span>
                    </div>

                    <div> 
                        <span>Offer ID</span> <span> {subscription.OfferId} </span>
                    </div>

                    <div> 
                        <span>Plan ID</span> <span> {subscription.PlanId} </span>
                    </div>                    

                    <div> 
                        <span>Quantity</span> <span> {subscription.Quantity} </span>
                    </div>                    

                    <div> 
                        <span>Status</span> <span> {SaasSubscriptionStatus[subscription.SaasSubscriptionStatus]} </span>
                    </div>                    

                    <div> 
                        <span>Start Date</span> 
                        <span> {moment(subscription.TermStartDate).format("DD-MMM-YYYY")} </span>
                    </div>

                    <div> 
                        <span>End Date</span> 
                        <span> {moment(subscription.TermEndDate).format("DD-MMM-YYYY")} </span>
                    </div>
                    <div> 
                        <span>Billing Term</span> <span> {subscription.TermUnit >=0 ? termUnits[subscription.TermUnit] : ""} </span>
                    </div>                    
                    <div> 
                        <span>Auto Renew</span> <span> {subscription.AutoRenew ? "Yes" : "No"} </span>
                    </div>

                    <div> 
                        <span>Purchaser</span> <span> {subscription.PurchaserEmailId} </span>
                    </div>

                    <div> 
                        <span>Beneficiary</span> <span> {subscription.BeneficiaryEmailId} </span>
                    </div>

                    { moment(subscription.TermEndDate).diff(moment(subscription.TermStartDate), 'days') < 10 && (
                        <Caption1Strong>
                            Please note that some details of the subscription may not be accurate, as we are still waiting to get the complete details from the marketplace. Please check after few minutes.
                        </Caption1Strong>
                    )}
                </div>

                <div className={localStyles.statusTiles}>
                        <Card>
                            <CardHeader
                                header={<Subtitle1>Tenant Status</Subtitle1>}
                            />
                            <CardPreview>
                                <div className={localStyles.cardBody}>
                                    { settings.tenantStatus === TenantSetupStatus.Configured ? 
                                    <><Subtitle2>Configured <CheckmarkCircleFilled color="green" /></Subtitle2>
                                    <Body1 block>Your tenant is configured. Use the 'Manage Sites' section to provision the Styklr components within a Communications site and start using them.</Body1>
                                    </>
                                    : settings.tenantStatus === TenantSetupStatus.Failed ? 
                                    <><Subtitle2>Setup Failed <ErrorCircleFilled color="darkred" /> </Subtitle2>
                                    <Body1 block color="darkred">We couldn't configure your tenant. Please contact support</Body1>
                                    </> : 
                                    <><Subtitle2><Spinner label={"Configuring"} labelPosition="before" as="div" size="small" /></Subtitle2>
                                    <Body1 block>
                                        Please wait while we configure your tenant and activate the subscription. This shouldn't take long.
                                    </Body1>
                                    </> }
                                </div>
                            </CardPreview>
                            {settings.tenantStatus === TenantSetupStatus.Failed &&(
                                <CardFooter>
                                    <ContactSupport />
                                </CardFooter>
                            )}
                        </Card>

                        <Card>
                            <CardHeader
                                header={<Subtitle1>Sites</Subtitle1>}
                            />
                            <CardPreview>
                                <Body1 className={localStyles.cardBody}>To use Styklr intranet, configure it in a site..</Body1>
                            </CardPreview>
                            <CardFooter>
                                <Button onClick={() => {navigate("/sites")}}>Manage Sites</Button>
                            </CardFooter>
                        </Card>
                </div>
            </>   
            }

            <div className={styles.pageSection}>
                <Button onClick={() => {navigate("/subscriptions")}}>All Subscriptions</Button>
            </div>
            
        </div>
    </div>
}






// (subscription?.SaasSubscriptionStatus === SaasSubscriptionStatus.PendingFulfillmentStart && settings.tenantStatus === TenantSetupStatus.NotConfigured) ? (<>
//     <Text block> Thank you for choosing Styklr Intranet. Please complete the configuration steps below to activate your subscription.</Text>
//     <div className={styles.pageSection}>

//     </div>
//     </>
// ):
// (subscription?.SaasSubscriptionStatus === SaasSubscriptionStatus.PendingFulfillmentStart && settings.tenantStatus === TenantSetupStatus.Configuring) ? (<>
//     <Text block> We are configuring your subscription. This could take a couple of minutes, and you will receive an email once it is completed.</Text>
//     </>
// ):
// (settings.tenantStatus === TenantSetupStatus.Failed) ? (<>
//     <Subtitle1>Error</Subtitle1>
//     <Text block> There is an unexpected error while configuring your subscription. Please reach out to support</Text>
//     <Text block>
//         Tenant ID : { subscription.PartitionKey }
//     </Text>
//     <Text block>                    
//         Subscription ID : { subscription.RowKey }
//     </Text>
//     </>
// ):