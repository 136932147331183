import { createContext, useEffect, useState } from 'react';
import { StyklrService } from './services/StyklrService';
import { BlockerMessage } from './components/BlockerMessage';

export interface IInfoContext {
    title:string,
    message:string   
}

export const UserContext = createContext<any>({});
export const InfoContext = createContext<{ info:IInfoContext, setInfo: React.Dispatch<React.SetStateAction<IInfoContext>> }>({
    info:{
        title:"",
        message:""
    },
    setInfo: () => {}
});

export const AppContext:React.FC<React.PropsWithChildren> = ({children}) => {

    const [userInfo, setUserInfo] = useState<any>({});
    const [info, setInfo] = useState<IInfoContext>({
        title:"",
        message:""
    });
    const [error, setError] = useState<number>(200);
    
    useEffect(() => {
        StyklrService.getUserRole()
        .then(res => {
            setError(200);
            console.dir(res);
            setUserInfo(res);
        }).catch(err => {
            setError(err)
        });
    }, []);

    return <UserContext.Provider value={userInfo}>
            <InfoContext.Provider value={ {info, setInfo} }>
                {error === 200? 
                    children : <BlockerMessage errorCode={error}/>
                }
            </InfoContext.Provider>
        </UserContext.Provider>
}