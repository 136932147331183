import { MsalAuthenticationResult } from "@azure/msal-react";
import { useEffect } from "react";

export const ErrorComponent: React.FC<MsalAuthenticationResult> = ({error}) => {

    /**
     * This is the error data when user does not consent the app.
     *  errorCode: "consent_required"
        errorMessage: "AADSTS65004: User declined to consent to access the app.\r\nTrace ID: e715ecd7-2f52-4d41-bd76-43514f4e0400\r\nCorrelation ID: cd6ded90-5fe0-4e2a-9270-2895037815ea\r\nTimestamp: 2023-04-24 12:02:28Z"
        name: "InteractionRequiredAuthError"
     */
    
    useEffect(() => {
        console.dir(error);
    }, [error]);
    
    return <div>An Error Occurred: {error ? error.errorCode : "unknown error"}</div>;
}