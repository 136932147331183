import { useEffect, useState, useContext } from "react";
import { SiteStatus, StyklrService } from "../../services/StyklrService";
import { Text, Spinner,
  TableBody,
  TableCell,
  TableRow,
  Table,
  TableHeader,
  TableHeaderCell,
  Button,
  TableCellLayout
} from "@fluentui/react-components";
import { appStyles } from "../../Styles";
import moment from "moment";
import { InfoContext } from "../../AppContext";

export const AdminSites:React.FC = () => {
    const { setInfo } = useContext(InfoContext);

    const styles = appStyles();

    const [sites, setSites] = useState<any[]>([]);
    const [ready, setReady] = useState<boolean>(false);

    useEffect(() => {
        StyklrService.getAdminSites()
        .then(res => {
            setSites(res);
            setReady(true);
        }).catch(error => {
            console.error(error);
        })
    }, []);

    const updateSite = (item:any) => {
        StyklrService.updateAdminSite(item).then(res => {
            setInfo({
                title: "Success",
                message: "Site installation started. Please refresh after few minutes to check the status"
            });
        }).catch(() => {
            setInfo({
                title: "Failed",
                message: "Site installation request failed. Please try again"
            });
        })
    }

    const tableColumns = [
        { columnKey: "WebUrl", label: "Site Url" },
        { columnKey: "TemplateId", label: "Template" },
        { columnKey: "Status", label: "Status" },
        { columnKey: "RequestedBy", label: "Requested By" },
        { columnKey: "Timestamp", label: "Last Updated" },
        { columnKey: "Actions", label: "" },
    ];

    return <div>
        <div className={styles.pageArea}>
        
        {sites && sites.length > 0 && (<>
        <Table aria-label="Table with cell actions">
          <TableHeader>
            <TableRow>
              {tableColumns.map((column) => (
                <TableHeaderCell key={column.columnKey}>
                  {column.label}
                </TableHeaderCell>
              ))}
            </TableRow>
          </TableHeader>
          <TableBody>
            {sites.map((item) => (
              <TableRow key={item.RowKey}>
                    <TableCell>
                        <TableCellLayout appearance="primary" style={{
                            wordBreak: "break-all"
                        }}>
                            {item.WebUrl}
                        </TableCellLayout>
                    </TableCell>
                    <TableCell>{item.TemplateId}</TableCell>
                    <TableCell>{SiteStatus[item.Status]}</TableCell>
                    <TableCell>{item.RequestedBy}</TableCell>
                    <TableCell>{ moment(item.Timestamp).format("DD-MMM-YYYY hh:mm:ss A") }</TableCell>
                    <TableCell>
                        {item.Status === SiteStatus.WaitingToProvision ? 
                            <> 
                                <Button
                                    onClick={() => {
                                        updateSite(item)
                                    }}
                                >Start</Button>
                            </>
                            :
                            <></>
                        }
                    </TableCell>

              </TableRow>
            ))}
          </TableBody>
        </Table>

        </>)}
        
        <div className={styles.pageSection}>
          {!(sites && sites.length > 0) && ready && (<Text block>No site installation requests found!</Text>)}
          {!ready && (<Spinner labelPosition="below" label={"Getting the site instllation requests"} />)}
        </div>
        </div>
    </div>

}