import { makeStyles, shorthands } from "@fluentui/react-components";

export const appStyles = makeStyles({

    pageTitle: {
        ...shorthands.margin("20px", "20px", "30px", "20px")
    },

    pageArea: {
        ...shorthands.margin("20px","20px","0px","20px")
    },

    pageSection: {
        ...shorthands.margin("20px", "0px", "0px", "0px")
    }
})