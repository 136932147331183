import { useEffect, useState } from "react";
import { SaasSubscriptionStatus, StyklrService } from "../../services/StyklrService";
import { Text, Spinner,
  TableBody,
  TableCell,
  TableRow,
  Table,
  TableHeader,
  TableHeaderCell,
  Button
} from "@fluentui/react-components";
import { useNavigate } from "react-router-dom";
import { appStyles } from "../../Styles";
import moment from "moment";
import { Add16Filled } from "@fluentui/react-icons";

export const AdminSubscriptions:React.FC = () => {
    const styles = appStyles();

    const navigate = useNavigate();

    const [subscriptions, setSubscriptions] = useState<any[]>([]);
    const [ready, setReady] = useState<boolean>(false);

    useEffect(() => {
        StyklrService.getSubscriptions(true)
        .then(res => {
            setSubscriptions(res);
            setReady(true);
        }).catch(error => {
            console.error(error);
        })
    }, []);

    const tableColumns = [
      { columnKey: "Name", label: "Subscription Name" },
      { columnKey: "OfferId", label: "Offer" },
      { columnKey: "PlanId", label: "Plan" },
      { columnKey: "BeneficiaryEmailId", label: "Subscribed By" },
      { columnKey: "SaasSubscriptionStatus", label: "Status" },
      { columnKey: "TermEndDate", label: "End Date" },
      { columnKey: "Actions", label: "" },
    ]
    return <div>
        <div className={styles.pageArea}>
        
        <Button 
          icon={<Add16Filled />}
          onClick={() => { navigate("/admin/subscriptions/new") }}>Add</Button>
        
        {subscriptions && subscriptions.length > 0 && (<>
        <Table aria-label="Table with cell actions">
          <TableHeader>
            <TableRow>
              {tableColumns.map((column) => (
                <TableHeaderCell key={column.columnKey}>
                  {column.label}
                </TableHeaderCell>
              ))}
            </TableRow>
          </TableHeader>
          <TableBody>
            {subscriptions.map((item) => (
              <TableRow key={item.RowKey}>
                <TableCell>{item.Name}</TableCell>
                <TableCell>{item.OfferId}</TableCell>
                <TableCell>{item.PlanId}</TableCell>
                <TableCell>{item.BeneficiaryEmailId}</TableCell>
                <TableCell>{SaasSubscriptionStatus[item.SaasSubscriptionStatus]}</TableCell>
                <TableCell>{ moment(item.TermEndDate).format("DD-MMM-YYYY") }</TableCell>
                <TableCell>
                    <Button onClick={() => { navigate(`/admin/subscriptions/edit/${item.RowKey}`) }}>
                      Details
                    </Button>
                </TableCell>

              </TableRow>
            ))}
          </TableBody>
        </Table>

        </>)}
        
        <div className={styles.pageSection}>
          {!(subscriptions && subscriptions.length > 0) && ready && (<Text block>No subscriptions found for your tenant.</Text>)}
          {!ready && (<Spinner labelPosition="below" label={"Getting the subscriptions"} />)}
        </div>
        </div>
    </div>

}