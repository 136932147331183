import { AccountInfo, InteractionStatus } from "@azure/msal-browser";
import { useMsal } from "@azure/msal-react";
import { makeStyles, tokens, Text, Popover, PopoverTrigger, PopoverSurface, Button } from "@fluentui/react-components"
import { useEffect, useState } from "react";
import ContactSupport from "../components/ContactSupport";


const styleClasses = makeStyles({
    header: {
        display: "flex",
        alignItems: "center",
        flexDirection: "row",
        backgroundColor: tokens.colorBrandBackground,
        color: tokens.colorNeutralForegroundOnBrand,
        height: "50px",
        paddingLeft: "10px",
        paddingRight: "10px",
    },
    siteTitle: {
        flexGrow: "1",
    },
    userInfo: {
        height: "100%",
        alignItems: "center",
        display: "flex",
        paddingRight: "15px"
    },
    buttonWrapper: {
        display: "flex",
        flexDirection: "column-reverse",
        alignItems: "flex-end"
    },
    infoCard: {
        display:"flex",
        flexDirection: "column",
        '> div': {
            display: "flex",
            flexDirection: "row",
            '> *': {
                paddingRight:"10px"
            }
        }
    }
})
export const Header:React.FC = () => {
    const { instance, inProgress, accounts } = useMsal();
    const [account, setAccount] = useState<AccountInfo>();

    const styles = styleClasses();

    useEffect(() => {
        if(inProgress === InteractionStatus.None) {
            setAccount(accounts[0]);
        }
    }, [accounts, inProgress])

    return <div className={styles.header}>
        <Text size={500} className={styles.siteTitle}>
            {process.env.REACT_APP_TITLE}
        </Text>
        <ContactSupport />
        <Popover>
            <PopoverTrigger>
                <div className={styles.userInfo}>{account?.name}</div>
            </PopoverTrigger>
            <PopoverSurface>

                <div className={styles.buttonWrapper}>
                <Button appearance="subtle" onClick={() => {
                    instance.logoutPopup({
                        account: account,
                        logoutHint: account?.username
                    });
                }}>Sign out</Button>
                </div>
                <div className={styles.infoCard}>
                    <div><Text>Username</Text><Text>{account?.username}</Text></div>
                    <div><Text>Tenant ID</Text><Text>{account?.tenantId}</Text></div>
                </div>
            </PopoverSurface>
        </Popover>
    </div>
}