import { Route, Routes } from "react-router-dom"
import { Home } from "./Home"
import { Subscriptions } from "./Subscriptions"
import { VerifySubscription } from "./VerifySubscription"
import { Admin } from "./Admin"
import { SubscriptionInfo } from "./SubscriptionInfo"
import { AppGeneric32Filled, AppsListDetail24Filled, Home24Filled, Settings24Filled } from "@fluentui/react-icons"
import { Settings } from "./Settings"
import { Dialog, DialogBody, DialogContent, DialogSurface, DialogTitle } from '@fluentui/react-components';
import { useContext } from "react"
import { InfoContext } from "../AppContext"
import { Sites } from "./Sites"
import { AdminSubscriptions } from "./Admin/AdminSubscriptions"
import { SubscriptionForm } from "./Admin/SubscriptionForm"
import { AdminSites } from "./Admin/AdminSites"


export const Pages:React.FC = () => {
    const { info, setInfo } = useContext(InfoContext);

    return <>
    <Routes>
        { pagesList.filter(p => p.isSuperAdmin !== true).map(pg => (<Route key={pg.id} path={pg.path} element= { <pg.component /> } /> )) }

        <Route key="admin" path="/admin" element= { <Admin /> }>
            <Route key="admin-subscriptions" path="/admin/subscriptions" element= { <AdminSubscriptions /> }> </Route>
            <Route key="admin-subscriptions" path="/admin/subscriptions/new" Component={ () => <SubscriptionForm newForm={true} /> }> </Route>
            <Route key="admin-subscriptions" path="/admin/subscriptions/edit/:id" Component={ () => <SubscriptionForm newForm={false} /> }> </Route>
            <Route key="admin-sites" path="/admin/sites" Component={ () => <AdminSites /> }> </Route>
        </Route>
    </Routes>

    <Dialog 
        modalType="non-modal"
        open={info.title.length > 0} onOpenChange={() => { setInfo({ title:"", message:"" }) }}>
        <DialogSurface>
            <DialogBody>
            <DialogTitle>{info.title}</DialogTitle>
            <DialogContent>{info.message}</DialogContent>
            </DialogBody>
        </DialogSurface>
    </Dialog>

    </>
}

export const pagesList = [
    
    { id:2, title:"Subscriptions", path:"/subscriptions", component: Subscriptions, icon: AppsListDetail24Filled  },
    { id:2.1, title:"Sites", path:"/sites", component: Sites, icon: AppGeneric32Filled  },
    { id:3, title:"Verify Subscription", icon: AppsListDetail24Filled, path:"/subscriptions/verify", component: VerifySubscription, hidden:true },
    { id:3.1, title:"Subscription Info", icon: AppsListDetail24Filled, path:"/subscriptions/:id", component: SubscriptionInfo, hidden:true },
    
    // Admin Only items
    { id:4, title:"Settings", icon: Settings24Filled, path:"/settings", component: Settings, isAdmin:true },
    
    // Super Admin Only items
    { id:5, title:"Admin", icon: Settings24Filled,  path:"/admin", component: Admin, isSuperAdmin: true },


    // Should always be the last item in this array
    { id:1, title:"Home", icon: Home24Filled, path:"/", component: Home },
]