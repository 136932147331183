import { msalInstance } from "..";
import { tokenScopes } from "../config/authConfig";
import { Client } from "@microsoft/microsoft-graph-client";

export const GraphService = {
    getAccessToken:async ():Promise<string> => {
        const token = await msalInstance.acquireTokenSilent({ scopes: tokenScopes.graph });
        return token.accessToken;
    },

    /**
     * Get the list of communication sites from the tenant
     */
    getCommunicationSites: async ():Promise<Array<any>> => {
        const client = Client.initWithMiddleware({
            authProvider: {
                getAccessToken: GraphService.getAccessToken
            }
        });

        const data = await client.api("/search/query").post({
            "requests": [
                {
                    "entityTypes": [
                        "site"
                    ],
                    "query": {
                        "queryString": "WebTemplate:SITEPAGEPUBLISHING AND contentclass:STS_SITE"
                    },
                    "trimDuplicates": true,
                    "from":0,
                    "size":500
                }
            ]
        })

        return data.value[0].hitsContainers[0].hits.map((i:any) => (i.resource)).filter((v:any,i:any,a:any)=>a.findIndex((v2:any)=>(v.id === v2.id))===i);
    },

    validateSite: async (siteId:string):Promise<boolean> => {
        const client = Client.initWithMiddleware({
            authProvider: {
                getAccessToken: GraphService.getAccessToken
            }
        });

        // Grant permission to site
        const data = await client.api(`/sites/${siteId}/permissions`).header("Content-Type", "application/json").post({
            "roles": ["fullcontrol"],
            "grantedToIdentities": [{
              "application": {
                "id": process.env.REACT_APP_CORE_CLIENT_ID,
                "displayName": "Styklr Core"
              }
            }]
        });
        return data;
    },

    getSiteId: async (siteUrl:string):Promise<string> => {
        const client = Client.initWithMiddleware({
            authProvider: {
                getAccessToken: GraphService.getAccessToken
            }
        });

        // Form the URL syntax for Graph if a custom site url is selected
        if(siteUrl.toLowerCase().indexOf("https://") !== -1) {
            var url = new URL(siteUrl);
            siteUrl = `${url.hostname}`;
            if(url.pathname.length > 0 && url.pathname !== "/") {
                siteUrl = `${siteUrl}:${url.pathname}`;
            }

            const siteInfo = await client.api(`/sites/${siteUrl}`).get();
            return siteInfo.id;
        }
        return "";
    },

    getSecurityGroups: async(name: string):Promise<Array<any>> => {
        const client = Client.initWithMiddleware({
            authProvider: {
                getAccessToken: GraphService.getAccessToken
            }
        });

        const data = await client.api(`/groups?$filter=securityEnabled eq true AND startsWith(displayName, '${name}')&$select=id,displayName`).get();
        return data.value;
    },

    getAllSecurityGroups: async():Promise<Array<any>> => {
        const client = Client.initWithMiddleware({
            authProvider: {
                getAccessToken: GraphService.getAccessToken
            }
        });

        const data = await client.api(`/groups?$filter=securityEnabled eq true&$select=id,displayName`).get();
        return data.value;
    }
}