import { useEffect, useState, useContext } from "react";
import { SaasSubscriptionStatus, StyklrService } from "../services/StyklrService";
import { Text, Title2, Spinner,
  TableBody,
  TableCell,
  TableRow,
  Table,
  TableHeader,
  TableHeaderCell,
  Button
} from "@fluentui/react-components";
import { useNavigate } from "react-router-dom";
import { appStyles } from "../Styles";
import moment from "moment";
import { InfoContext } from "../AppContext";

export const Subscriptions:React.FC = () => {
    const styles = appStyles();

    const navigate = useNavigate();
    const { setInfo } = useContext(InfoContext);
    const [subscriptions, setSubscriptions] = useState<any[]>([]);
    const [ready, setReady] = useState<boolean>(false);
    const [processing, setProcessing] = useState<boolean>(false);

    useEffect(() => {
        StyklrService.getSubscriptions()
        .then(res => {
            setSubscriptions(res);
            setReady(true);
        }).catch(error => {
            console.error(error);
        })
    }, []);

    const activateSubscription = (item:any) => {
      setProcessing(true);
      StyklrService.activateSubscription(item).then(() => {
        navigate(`/subscriptions/${item.RowKey}`);
      }).catch(() => {
        setInfo({
          title: "Error",
          message: "Failed to activate the subscription. Please try again"
        });
      }).finally(() => {
        setProcessing(false);
      });
    }

    const tableColumns = [
      { columnKey: "Name", label: "Subscription Name" },
      { columnKey: "OfferId", label: "Offer" },
      { columnKey: "PlanId", label: "Plan" },
      { columnKey: "BeneficiaryEmailId", label: "Subscribed By" },
      { columnKey: "SaasSubscriptionStatus", label: "Status" },
      { columnKey: "TermEndDate", label: "End Date" },
      { columnKey: "Actions", label: "" },
    ];
    return <div>
        <Title2 block className={styles.pageTitle}>Subscriptions</Title2>
        <div className={styles.pageArea}>
        {subscriptions && subscriptions.length > 0 && (<>
        <Table aria-label="Table with cell actions">
          <TableHeader>
            <TableRow>
              {tableColumns.map((column) => (
                <TableHeaderCell key={column.columnKey}>
                  {column.label}
                </TableHeaderCell>
              ))}
            </TableRow>
          </TableHeader>
          <TableBody>
            {subscriptions.map((item) => (
              <TableRow key={item.RowKey}>
                <TableCell>{item.Name}</TableCell>
                <TableCell>{item.OfferId}</TableCell>
                <TableCell>{item.PlanId}</TableCell>
                <TableCell>{item.BeneficiaryEmailId}</TableCell>
                <TableCell>{SaasSubscriptionStatus[item.SaasSubscriptionStatus]}</TableCell>
                <TableCell>{ moment(item.TermEndDate).format("DD-MMM-YYYY") }</TableCell>
                <TableCell>
                    {item.SaasSubscriptionStatus === SaasSubscriptionStatus.NotStarted ? 
                      <Button disabled={processing} onClick={() => { activateSubscription(item); }}>
                        { processing ? "Activating" : "Activate" }
                      </Button>
                      :
                      <Button onClick={() => { navigate(`/subscriptions/${item.RowKey}`) }}>
                        Details
                      </Button>
                    }
                </TableCell>

              </TableRow>
            ))}
          </TableBody>
        </Table>

        </>)}
        
        <div className={styles.pageSection}>
          {!(subscriptions && subscriptions.length > 0) && ready && (<Text block>No subscriptions found for your tenant.</Text>)}
          {!ready && (<Spinner labelPosition="below" label={"Getting the subscriptions"} />)}
        </div>
        </div>
    </div>

}