import { useEffect, useState } from "react"
import { StyklrService } from "../services/StyklrService";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Spinner, Text, Title2, makeStyles, tokens } from "@fluentui/react-components";
import { appStyles } from "../Styles";

const styleClasses = makeStyles({
    root: {
        display:"flex",
        height: "100%",
        alignItems: "center",
        '> *': {
            flexGrow: 1,
            textAlign: "center",
            fontSize: tokens.fontSizeBase300,
            fontWeight: tokens.fontWeightBold
        }
    }
})

export const VerifySubscription:React.FC = () => {

    const styles = appStyles();
    const localStyles = styleClasses();


    const navigate = useNavigate();
    const [searchParams ] = useSearchParams();
    const [ error, setError ] = useState<boolean>(false);

    

    useEffect(() => {
        const subscriptionToken = searchParams.get("token");
        if(subscriptionToken && subscriptionToken.length > 0){
            StyklrService.resolveSubscription(subscriptionToken)
            .then(res => {
                // Validated the subscription. Redirect to Subscription info page
                navigate(`/subscriptions/${res.Id}`);
            })
            .catch(error => {
                console.error(error);
                setError(true);
            })
        } else {
            // Token not found in URL
            //Show message
            setError(true);
        }
    }, [navigate, searchParams]);

    return <>
        <Title2 block className={styles.pageTitle}>Subscriptions</Title2>
        <div><div className={localStyles.root}>
        {error ? 
            <Text>We couldn't identify this purchase. Reopen this SaaS subscription in the Azure portal or in Microsoft 365 Admin Center and select "Configure Account" or "Manage Account" again</Text> :   
            <Spinner size={"large"} labelPosition="below" label="Please wait, while we validate your subscription" />
        }
    </div></div>
    </>
}