import { msalInstance } from "..";
import { tokenScopes } from "../config/authConfig";

const baseURI:string = process.env.REACT_APP_API_BASE_URI as string;

export const StyklrService = {
    getAccessToken:async ():Promise<string> => {
        const token = await msalInstance.acquireTokenSilent({ scopes: tokenScopes.appService });
        return token.accessToken;
    },
    resolveSubscription:async (token:string):Promise<any> => {
        return new Promise(async (resolve, reject) => {
            const accessToken:string = await StyklrService.getAccessToken();
            const res = await fetch(`${baseURI}/api/subscriptions/resolve`, { 
                method:"POST",
                headers: {
                    "Authorization": `Bearer ${accessToken}`,
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    token
                })
            });
            if(res.status === 200) {
                resolve(await res.json());
            } else {
                reject(res.status);
            }
        });
    },

    getSubscriptions:async (offlineSubscriptions:boolean = false):Promise<any[]> => {
        return new Promise(async (resolve, reject) => {
            var uri = offlineSubscriptions === true ? '/api/adm/subscriptions' : '/api/subscriptions'
            const accessToken:string = await StyklrService.getAccessToken();
            const res = await fetch(`${baseURI}${uri}`, { 
                method:"GET",
                headers: {
                    "Authorization": `Bearer ${accessToken}`,
                    "Content-Type": "application/json",
                }
            });

            if(res.status === 200) {
                resolve(await res.json());
            } else {
                reject(res.status);
            }
        });
    },

    addSubscription: async(item:any):Promise<any> => {
        return new Promise(async (resolve, reject) => {
            const accessToken:string = await StyklrService.getAccessToken();
            const res = await fetch(`${baseURI}/api/adm/subscriptions`, { 
                method:"POST",
                headers: {
                    "Authorization": `Bearer ${accessToken}`,
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(item)
            });
            if(res.status === 200 || res.status === 202) {
                resolve("");
            } else {
                reject(res.status);
            }
        });
    },
    updateSubscription: async(item:any):Promise<any> => {
        return new Promise(async (resolve, reject) => {
            const accessToken:string = await StyklrService.getAccessToken();
            const res = await fetch(`${baseURI}/api/adm/subscriptions`, { 
                method:"PATCH",
                headers: {
                    "Authorization": `Bearer ${accessToken}`,
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(item)
            });
            if(res.status === 200 || res.status === 202) {
                resolve("");
            } else {
                reject(res.status);
            }
        });
    },
    activateSubscription: async(item:any):Promise<any> => {
        return new Promise(async (resolve, reject) => {
            const accessToken:string = await StyklrService.getAccessToken();
            const res = await fetch(`${baseURI}/api/subscriptions/activate`, { 
                method:"POST",
                headers: {
                    "Authorization": `Bearer ${accessToken}`,
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(item)
            });
            if(res.status === 200 || res.status === 202) {
                resolve("");
            } else {
                reject(res.status);
            }
        });
    },    

    getSubscriptionById: async (id:string):Promise<any> => {
        return new Promise(async (resolve, reject) => {
            const accessToken:string = await StyklrService.getAccessToken();
            const res = await fetch(`${baseURI}/api/subscriptions/${id}`, {
                method:"GET",
                headers: {
                    "Authorization": `Bearer ${accessToken}`,
                    "Content-Type": "application/json",
                }            
            })
    
            if(res.status === 200) {
                resolve(await res.json());
            } else {
                reject(res.status);
            }
        });
    },
    
    getUserRole: async ():Promise<any> => {
        return new Promise(async (resolve, reject) => {
            const accessToken:string = await StyklrService.getAccessToken();
            const res = await fetch(`${baseURI}/api/userInfo`, { 
                method:"GET",
                headers: {
                    "Authorization": `Bearer ${accessToken}`,
                    "Content-Type": "application/json",
                }
            })
            if(res.status === 200) {
                resolve(await res.json());
            } else {
                reject(res.status);
            }
        });
    },

    validateSite: async (siteId:string):Promise<boolean> => {
        return new Promise(async (resolve, reject) => {
            const accessToken:string = await StyklrService.getAccessToken();
            const res = await fetch(`${baseURI}/api/sites/validate`, { 
                method:"POST",
                headers: {
                    "Authorization": `Bearer ${accessToken}`,
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    siteId: siteId
                })
            })

            if(res.status === 200) {
                resolve(true);
            } else {
                reject(res.status);
            }
        });
    },

    addSite: async (siteId:string, templateId:string, webUrl:string, requestedBy:string):Promise<any> => {
        return new Promise(async (resolve, reject) => {
            const accessToken:string = await StyklrService.getAccessToken();
            const res = await fetch(`${baseURI}/api/sites`, { 
                method:"POST",
                headers: {
                    "Authorization": `Bearer ${accessToken}`,
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    siteId: siteId,
                    templateId: templateId,
                    webUrl: webUrl,
                    requestedBy
                })
            })
            if(res.status === 200) {
                resolve(await res.json());
            } else {
                reject(res.status);
            }
        });
    },

    getSites: async ():Promise<Array<any>> => {
        return new Promise(async (resolve, reject) => {
            const accessToken:string = await StyklrService.getAccessToken();
            const res = await fetch(`${baseURI}/api/sites`, { 
                method:"GET",
                headers: {
                    "Authorization": `Bearer ${accessToken}`,
                    "Content-Type": "application/json",
                }
            })
            if(res.status === 200) {
                resolve(await res.json());
            } else {
                reject(res.status);
            }
        });
    },

    getAdminSites: async ():Promise<Array<any>> => {
        return new Promise(async (resolve, reject) => {
            const accessToken:string = await StyklrService.getAccessToken();
            const res = await fetch(`${baseURI}/api/adm/sites`, { 
                method:"GET",
                headers: {
                    "Authorization": `Bearer ${accessToken}`,
                    "Content-Type": "application/json",
                }
            })
            if(res.status === 200) {
                resolve(await res.json());
            } else {
                reject(res.status);
            }
        });
    },

    updateAdminSite: async (item:any):Promise<any> => {
        return new Promise(async (resolve, reject) => {
            const accessToken:string = await StyklrService.getAccessToken();
            const res = await fetch(`${baseURI}/api/adm/sites`, { 
                method:"POST",
                headers: {
                    "Authorization": `Bearer ${accessToken}`,
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(item)
            })
            if(res.status === 200) {
                resolve(await res.json());
            } else {
                reject(res.status);
            }
        });
    },


    getSettings: async():Promise<any> => {
        return new Promise(async (resolve, reject) => {
            const accessToken:string = await StyklrService.getAccessToken();
            const res = await fetch(`${baseURI}/api/settings`, { 
                method:"GET",
                headers: {
                    "Authorization": `Bearer ${accessToken}`,
                    "Content-Type": "application/json",
                }
            });
    
            if(res.status === 200) {
                resolve(await res.json());
            } else {
                reject(res.status);
            }
        });
    },

    saveSettings: async(settings:any):Promise<any> => {
        return new Promise(async (resolve, reject) => {
            const accessToken:string = await StyklrService.getAccessToken();
            const res = await fetch(`${baseURI}/api/settings`, { 
                method:"POST",
                headers: {
                    "Authorization": `Bearer ${accessToken}`,
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(settings)
            });
            if(res.status === 200) {
                resolve("");
            } else {
                reject(res.status);
            }
        });
    },

    updateTenant: async():Promise<any> => {
        return new Promise(async (resolve, reject) => {
            const accessToken:string = await StyklrService.getAccessToken();
            const res = await fetch(`${baseURI}/api/settings/tenant`, { 
                method:"POST",
                headers: {
                    "Authorization": `Bearer ${accessToken}`,
                    "Content-Type": "application/json",
                }
            });
            if(res.status === 202 || res.status === 200) {
                resolve("");
            } else {
                reject(res.status);
            }
        });
    }, 


}

export enum SiteStatus {
    WaitingToProvision,
    Provisioning,
    Provisioned,
    Failed
}

export enum TenantSetupStatus {
    NotConfigured = 0,
    Configuring = 1,
    Configured = 2,
    Failed = 3
}

export enum SaasSubscriptionStatus {
    NotStarted,
    PendingFulfillmentStart,
    Subscribed,
    Suspended,
    Unsubscribed
}