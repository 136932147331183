import { makeStyles, tokens } from "@fluentui/react-components"
import { Header } from "./Header";
import { SideBar } from "./SideBar";

const styleClasses = makeStyles({ 
    container: {
        display: 'flex',
        flexDirection: 'column',
        flexWrap: 'nowrap',
        width: 'auto',
        height: '100vh',
        boxSizing: 'border-box',
        '> *': {
        textOverflow: 'ellipsis',
        },
        '> :not(:first-child)': {
        marginTop: '0px',
        },
        '> *:not(.ms-StackItem)': {
        flexShrink: 1,
        },
    },

    contentArea: {
        flexGrow: 1,
        height: "calc(100vh - 50px)",        
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'nowrap',
        width: 'auto',
        boxSizing: 'border-box',
        '> *': {
        textOverflow: 'ellipsis',
        },
        '> :not(:first-child)': {
        marginLeft: '0px',
        },
        '> *:not(.ms-StackItem)': {
        flexShrink: 1,
        },
    },
    sideBarArea:{
        backgroundColor: tokens.colorNeutralBackground4,
        width:"250px",
    },
    pageArea: {
        flexGrow: 1,
        height: "100%",
        overflowY: "scroll",
    }
});


export const PageLayout: React.FC<React.PropsWithChildren> = ({children}) => {
    const styles = styleClasses();

    return <div className={styles.container}>
        <Header />
        <div className={styles.contentArea}>
            <div className={styles.sideBarArea}> <SideBar /> </div>
            <div className={styles.pageArea}> {children} </div>
        </div>
    </div>
}