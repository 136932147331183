import { Button, Combobox, Field, Input, InputOnChangeData, Title3, Option
} from "@fluentui/react-components"

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import { appStyles } from "../../Styles";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState, useContext } from "react";
import moment from "moment";
import { StyklrService } from "../../services/StyklrService";
import { InfoContext } from "../../AppContext";


export interface ISubscriptionFormProps {
    newForm: boolean
}

export const SubscriptionForm:React.FC<ISubscriptionFormProps> = ({  newForm }) => {
    const styles = appStyles();
    const { id } = useParams();

    const [PartitionKey, setPartitionKey] = useState<string>("");
    const [RowKey, setRowKey] = useState<string>("new-value");
    const [BeneficiaryEmailId, setBeneficiaryEmailId] = useState<string>("");
    const [Name, setName] = useState<string>("");
    const [OfferId, setOfferId] = useState<string>("");
    const [PlanId, setPlanId] = useState<string>("");
    const [PurchaserEmailId, setPurchaserEmailId] = useState<string>("");
    const [SaasSubscriptionStatus, setSaasSubscriptionStatus] = useState<string>("NotStarted");
    const [TermUnit, setTermUnit] = useState<string>("");
    const [TermEndDate, setTermEndDate] = useState<Date>(new Date());
    const [TermStartDate, setTermStartDate] = useState<Date>(new Date());
    const [Source, setSource] = useState<string>("");
    const [Price, setPrice] = useState<string>("");

    const [saving, setSaving] = useState<boolean>(false);

    const termUnits = ["P1M", "P1Y", "P2Y", "P3Y"];
    const subsStatus = ["NotStarted","PendingFulfillmentStart","Subscribed","Suspended","Unsubscribed"];

    useEffect(() => {
        if(id && id.length > 0){
            StyklrService.getSubscriptionById(id).then(res => {
                const subs = res.subscription;
                setPartitionKey(subs.PartitionKey);
                setRowKey(subs.RowKey);
                setBeneficiaryEmailId(subs.BeneficiaryEmailId);
                setName(subs.Name);
                setOfferId(subs.OfferId);                
                setPlanId(subs.PlanId);
                setPurchaserEmailId(subs.PurchaserEmailId);
                setSaasSubscriptionStatus(subsStatus[subs.SaasSubscriptionStatus]);
                setTermUnit(termUnits[subs.TermUnit]);
                setTermStartDate(moment(subs.TermStartDate).toDate());
                setTermEndDate(moment(subs.TermEndDate).toDate());
                setSource(subs.Source);
                setPrice(subs.Price);
            }).catch(() => {
                console.error("Falied to find a subscription with id " + id);
            })
        }
    });

    const submitForm = () => {
        setSaving(true);
        const item = {
            PartitionKey,
            RowKey,
            AutoRenew: false,
            BeneficiaryEmailId,
            BeneficiaryTenantId:PartitionKey,
            Name,
            OfferId,
            PlanId,
            PurchaserEmailId,
            PurchaserTenantId:Source,
            PublisherId:"styklr-intranet-offline",
            SaasSubscriptionStatus:subsStatus.indexOf(SaasSubscriptionStatus),
            TermUnit:termUnits.indexOf(TermUnit),
            TermStartDate:moment(TermStartDate).utc().startOf("day").toISOString(),
            TermEndDate:moment(TermEndDate).utc().startOf("day").toISOString(),
            Source,
            Price
        };
        if(newForm) {
            StyklrService.addSubscription(item)
            .then(() => {
                alert("Saved!!");
            })
            .catch(() => {
                alert("Failed!!");
            }).finally(() => {
                setSaving(false);
            });
        } else {
            StyklrService.updateSubscription(item)
            .then(() => {
                alert("Saved!!");
            })
            .catch(() => {
                alert("Failed!!");
            }).finally(() => {
                setSaving(false);
            });
        }
    }

    return <div>
        <Title3 block className={styles.pageTitle}> { newForm ? "New Subscription" : "Edit Subscription" } </Title3>
        <div className={styles.pageArea}>
            <Field label={"Subscription ID"}>
                <Input readOnly disabled value={RowKey} />
            </Field>
            <Field label={"Tenant ID"}>
                <Input disabled={!newForm} readOnly={!newForm} value={PartitionKey} onChange={(ev: React.ChangeEvent<HTMLInputElement>, data: InputOnChangeData) => { setPartitionKey(data.value.toLowerCase()); }} />
            </Field>
            <Field label={"Subscription Name"}>
                <Input value={Name} onChange={(ev: React.ChangeEvent<HTMLInputElement>, data: InputOnChangeData) => { setName(data.value); }} />
            </Field>            
            <Field label={"Customer Global Admin ID"}>
                <Input value={BeneficiaryEmailId} onChange={(ev: React.ChangeEvent<HTMLInputElement>, data: InputOnChangeData) => { setBeneficiaryEmailId(data.value); }} />
            </Field>
            <Field label={"Offer ID"}>
                <Input value={OfferId} onChange={(ev: React.ChangeEvent<HTMLInputElement>, data: InputOnChangeData) => { setOfferId(data.value); }} />
            </Field>            
            <Field label={"Plan ID"}>
                <Input value={PlanId} onChange={(ev: React.ChangeEvent<HTMLInputElement>, data: InputOnChangeData) => { setPlanId(data.value); }} />
            </Field>            
            <Field label={"Purchaser Email ID"}>
                <Input value={PurchaserEmailId} onChange={(ev: React.ChangeEvent<HTMLInputElement>, data: InputOnChangeData) => { setPurchaserEmailId(data.value); }} />
            </Field>            
            <Field label={"Subscription Status"}>
                <Combobox
                    disabled={newForm}
                    readOnly={newForm}
                    value={SaasSubscriptionStatus}
                    onOptionSelect={(event, data) => {
                        setSaasSubscriptionStatus(data.optionText as string);
                    }}
                >{subsStatus.map(status => <Option key={status} text={status}>{status}</Option>)}
                </Combobox>
            </Field>        
            <Field label={"Billing Term Unit"}>
                <Combobox
                    value={TermUnit}
                    onOptionSelect={(event, data) => {
                        const val = data.optionText as string;
                        setTermUnit(val);
                        var endDate = TermStartDate;
                        switch(val) {
                            case "P1Y": 
                                endDate = moment(endDate).add(1, "years").add(-1, "day").toDate();
                            break;
                            case "P2Y": 
                                endDate = moment(endDate).add(2, "years").add(-1, "day").toDate();
                            break;
                            case "P3Y": 
                                endDate = moment(endDate).add(3, "years").add(-1, "day").toDate();
                            break;
                        }
                        setTermEndDate(endDate);
                    }}
                >
                    {termUnits.map((unit, index) => <Option disabled={index === 0} key={unit} text={unit}>{unit}</Option>)}
                </Combobox>
            </Field>
            <Field label={"Price"}>
                <Input value={Price} onChange={(ev: React.ChangeEvent<HTMLInputElement>, data: InputOnChangeData) => { setPrice(data.value); }} />
            </Field>

            <Field label={"Start Date"}>
                <DatePicker
                    selected={TermStartDate}
                    onChange={
                        setTermStartDate as (date: Date | null | undefined) => void
                    }
                />    
            </Field>
            <Field label={"End Date"}>
                <DatePicker
                    selected={TermEndDate}
                    onChange={
                        setTermEndDate as (date: Date | null | undefined) => void
                    }
                />    
            </Field>            
                    

            <Field label={"Source"}>
                <Input value={Source} onChange={(ev: React.ChangeEvent<HTMLInputElement>, data: InputOnChangeData) => { setSource(data.value); }} />
            </Field>            
            <div className={styles.pageSection}>
                <Button disabled={saving} onClick={submitForm}>Save</Button>
            </div>
        </div>    
    </div>
}