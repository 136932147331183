import { Button, Combobox, ComboboxProps, Field, Option, Title2 } from "@fluentui/react-components"
import { appStyles } from "../Styles";
import { useState, useEffect, useMemo, useContext } from 'react';
import { GraphService } from "../services/GraphService";
import debounce from 'lodash.debounce';
import { StyklrService } from "../services/StyklrService";
import { InfoContext, UserContext } from "../AppContext";

export const Settings:React.FC = () => {
    const { setInfo } = useContext(InfoContext);
    const userInfo = useContext(UserContext);

    const styles = appStyles();

    const [matchingGroups, setMatchingGroups] = useState<any[]>([]);
    const [allGroups, setAllGroups] = useState<any[]>([]);

    const [selectedGroupId, setSelectedGroupId] = useState<string[]>([]);
    const [selectedGroup, setSelectedGroup] = useState<string>("");

    const [saving, setSaving] = useState<boolean>(false);
    const [error, setError] = useState<string>("");

    const onGraphSearch: ComboboxProps["onChange"] = async (event) => {
        const value = event.target.value.trim();
        if(value && value.length) {
            // Get groups from Graph
            const groups = await GraphService.getSecurityGroups(value);
            setMatchingGroups(groups);
        } else {
            setMatchingGroups([]);
        }
    };

    const onGroupSearch = useMemo(() => debounce(onGraphSearch, 1000), []);

    const onGroupSelected = (ev:any, data:any) => {
        setSelectedGroupId(data.selectedOptions);
        setSelectedGroup(data.optionText ?? "");
    };

    const onSave = () => {
        if(selectedGroup && selectedGroup.length > 0) {
            setSaving(true);
            StyklrService.saveSettings({
                adminGroupId: selectedGroup
            }).then((res) => { 
                setInfo({
                    title: "Saved",
                    message: "Settings saved successfully!"
                });
            }).catch(() => {
                setInfo({
                    title: "Error",
                    message: "An unexpected error has occured while saving. Please try again"
                });
            }).finally(() => {
                setSaving(false);
            });
        }
    }

    const [tenantRequestSubmitted, setTenantRequestSubmitted] = useState<boolean>(false);
    const updateTenant = () => {
        setTenantRequestSubmitted(true);
        StyklrService.updateTenant().then(res => { 
            setInfo({
                title: "Request Submitted",
                message: "Your tenant will be reconfigured and you will receive an email once it is completed"
            });
         }).catch(() => {
            setTenantRequestSubmitted(false);
            setInfo({
                title: "Error",
                message: "An unexpected error has occured while saving. Make sure you have Global Admin rights and try again."
            });
         })
    }

    useEffect(() => {
        StyklrService.getSettings().then(res => {
            setSelectedGroup(res.adminGroupId);
        }).catch(() => {
            // Settings not found. So we are disabling the page
            setError("We faced an unexpected issue while loading your settings. Please try again!")
        });

        // get all security groups
        GraphService.getAllSecurityGroups().then(res => {
            setAllGroups(res);
        }).catch(() => {
            setAllGroups([]);
        });

    }, []);

    return <div>
        <Title2 block className={styles.pageTitle}>Settings</Title2>
        <div className={styles.pageArea}>
            {error.length > 0 ? <div> {error} </div> : <>
                <Field label={"Administrators (Select an AD Security Group)"}>
                    <Combobox
                        freeform
                        placeholder="Select a group"
                        onChange={onGroupSearch}
                        onOptionSelect={onGroupSelected}
                        selectedOptions={selectedGroupId}
                        value={selectedGroup}
                    >
                        {matchingGroups && matchingGroups.length ? matchingGroups.map((option) => (
                            <Option key={option.id} value={option.id} text={option.displayName}>
                                {option.displayName}
                            </Option>
                        )):
                        allGroups.map((option) => (
                            <Option key={option.id} value={option.id} text={option.displayName}>
                                {option.displayName}
                            </Option>
                        ))
                    }
                    </Combobox>
                </Field>
                <div className={styles.pageSection}>
                    <Button 
                        disabled={saving}
                        appearance="primary" onClick={onSave}>
                        {saving ? "Saving" : "Save"}
                    </Button>
                </div>
                </>
            }
        </div>

        <Title2 block className={styles.pageTitle}>
            Update Tenant
        </Title2>
        <div className={styles.pageArea}>
            If you see any unexpected behaviors for the Styklr Intranet webparts, a Global Admin can update the tenant configurations. Click on the Update button below to submit a request for Tenant reconfiguration.
            <div className={styles.pageSection}>
            <Button 
                disabled={!userInfo.isGlobalAdmin || tenantRequestSubmitted}
                appearance="primary" onClick={updateTenant}>
                    Update
            </Button>
            </div>
        </div>
    </div>
}