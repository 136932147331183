import { Title2,
    Tab,
    TabList,
} from "@fluentui/react-components"
import { appStyles } from "../Styles";
import { Outlet, useNavigate } from "react-router-dom";

export const Admin:React.FC = () => {
    const styles = appStyles();
    const navigate = useNavigate();

    return <div>
        <Title2 block className={styles.pageTitle}>Product Management</Title2>
        <div className={styles.pageArea}>
            <TabList defaultSelectedValue="tab2">
                <Tab value="Subscriptions" onClick={ () => navigate("/admin/subscriptions") }>Subscriptions</Tab>
                <Tab value="Sites" onClick={ () => navigate("/admin/sites") }>Sites</Tab>
            </TabList>
            
            <Outlet />
        </div>    
    </div>
}
