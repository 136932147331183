import { useNavigate } from "react-router-dom";

// MSAL imports
import { MsalProvider, MsalAuthenticationTemplate } from "@azure/msal-react";
import { CustomNavigationClient } from "./utils/NavigationClient";
import { IPublicClientApplication, InteractionType } from '@azure/msal-browser';
import { loginRequest } from './config/authConfig';
import { ErrorComponent } from './utils/MSALErrorComponent';
import { PageLayout } from './layout/PageLayout';
import { Pages } from './pages/Pages';
import { AppContext } from './AppContext';

type AppProps = {
  pca: IPublicClientApplication;
};

function App({pca}: AppProps) {

  const navigate = useNavigate();
  const navigationClient = new CustomNavigationClient(navigate);
  pca.setNavigationClient(navigationClient);

  return (
    <MsalProvider instance={pca}>
      <MsalAuthenticationTemplate 
        interactionType={InteractionType.Redirect} authenticationRequest={loginRequest}
        errorComponent={ErrorComponent}
        >
          <AppContext>
            <PageLayout>
              <Pages />
            </PageLayout>
          </AppContext>
      </MsalAuthenticationTemplate>
    </MsalProvider>
  );
}

export default App;
