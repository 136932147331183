import { Button, Dialog, DialogContent, DialogSurface, Link, Spinner, Table, TableBody, TableCell, TableCellLayout, TableHeader, TableHeaderCell, TableRow, Text, Title2 } from "@fluentui/react-components"
import { appStyles } from "../Styles";
import { useState, useEffect } from "react";
import { Add16Filled } from "@fluentui/react-icons";
import { NewSiteRequest } from "../components/NewSiteRequest";
import { SiteStatus, StyklrService } from "../services/StyklrService";
import moment from "moment";
import { useAccount } from "@azure/msal-react";

export const Sites:React.FC = () => {
    const account = useAccount();

    const [sites, setSites] = useState<Array<any>>([]);
    const [ready, setReady] = useState<boolean>(false);

    const [showNewForm, setShowNewForm] = useState<boolean>(false);
    const styles = appStyles();

    useEffect(() => {
        if(showNewForm === false) {
            StyklrService.getSites()
            .then(res => {
                setSites(res);
                setReady(true);
            }).catch(error => {
                console.error(error);
            });
        }
    }, [showNewForm]);

    useEffect(() => {
        let provisioningSites = sites.filter(s => s.Status === SiteStatus.Provisioning);
        if(provisioningSites && provisioningSites.length > 0) {
            setTimeout(() => {
                StyklrService.getSites()
                .then(res1 => {
                    setSites(res1);
                }).catch(error => { console.error(error); });
            }, 5000);
        }
    }, [sites]);

    const retry = (site:any) => {

        const sIndex = sites.findIndex(i => i.RowKey === site.RowKey);
        let newSites = [...sites];
        newSites[sIndex] = { 
            ...site,
            Status: SiteStatus.Provisioning
        };
        setSites(newSites);

        StyklrService.addSite(site.RowKey, site.TemplateId, site.WebUrl, account?.username as string).then(res => {
            setShowNewForm(false);
        }).catch(e => {
            setShowNewForm(false);
        });
    }

    const tableColumns = [
        { columnKey: "WebUrl", label: "Site Url" },
        { columnKey: "TemplateId", label: "Template" },
        { columnKey: "Status", label: "Status" },
        { columnKey: "RequestedBy", label: "Requested By" },
        { columnKey: "Timestamp", label: "Last Updated" },
      ];

    return <div>
        <Title2 block className={styles.pageTitle}>Sites</Title2>
        <div className={styles.pageArea}>
            
            <Button icon={<Add16Filled />}
            onClick={() => { setShowNewForm(true); }}
            >New</Button>

            {sites && sites.length > 0 && (<>
            <Table aria-label="Table with cell actions">
            <TableHeader>
                <TableRow>
                {tableColumns.map((column) => (
                    <TableHeaderCell key={column.columnKey}>
                    {column.label}
                    </TableHeaderCell>
                ))}
                </TableRow>
            </TableHeader>
            <TableBody>
                {sites.map((item) => (
                <TableRow key={item.RowKey}>
                    <TableCell>
                        <TableCellLayout appearance="primary" style={{
                            wordBreak: "break-all"
                        }}>
                            {item.WebUrl}
                        </TableCellLayout>
                    </TableCell>
                    <TableCell>{item.TemplateId}</TableCell>
                    <TableCell>{SiteStatus[item.Status]} &nbsp; {(item.Status as SiteStatus) === SiteStatus.Failed && ( <Link style={{ fontWeight:"bold" }} onClick={() => { retry(item); }}>Retry</Link> )}</TableCell>
                    <TableCell>{item.RequestedBy}</TableCell>
                    <TableCell>{ moment(item.Timestamp).format("DD-MMM-YYYY hh:mm:ss A") }</TableCell>
                </TableRow>
                ))}
            </TableBody>
            </Table>

            </>)}


        <div className={styles.pageSection}>
          {!(sites && sites.length > 0) && ready && (<Text block>No sites installations found for your tenant.</Text>)}
          {!ready && (<Spinner labelPosition="below" label={"Getting the site installations"} />)}
        </div>

        </div>    

        
        


        <Dialog 
            modalType="modal"
            open={showNewForm}>
            <DialogSurface>
            <DialogContent>
                <NewSiteRequest 
                onSave={() => {
                    setShowNewForm(false);
                }} 
                onCancel={() => { setShowNewForm(false); }}
                title="Apply Template" 
                
                />
            </DialogContent>
            </DialogSurface>
        </Dialog>
    </div>
}