import { AccountInfo, InteractionStatus } from "@azure/msal-browser";
import { useMsal } from "@azure/msal-react";
import { Button, makeStyles } from "@fluentui/react-components";
import { PersonSupport24Filled } from "@fluentui/react-icons";
import { useEffect, useState } from "react";

const localStyles = makeStyles({
    root: {
        paddingRight: "30px",
        color: "#FFFFFF",
        fontWeight: "bold"
    }
})
const ContactSupport:React.FC = () => {
    const pageStyles = localStyles();

    const { inProgress, accounts } = useMsal();
    const [account, setAccount] = useState<AccountInfo>();

    useEffect(() => {
        if(inProgress === InteractionStatus.None) {
            setAccount(accounts[0]);
        }
    }, [accounts, inProgress])

    return(<div className={pageStyles.root}>
        <Button as="a" appearance="primary" href={`mailto:support@styklr.com?subject=Styklr Intranet support request&body=TenantId=${account?.tenantId}%0D%0A%0D%0A<<Describe your issue here and provide screenshots if possible>>`}>
            <PersonSupport24Filled /> &nbsp; Contact Support
        </Button>
    </div>);
}

export default ContactSupport